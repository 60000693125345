<template>
  <div class="plat" v-loading.fullscreen.lock="fullscreenLoading">
    <div class="p-header">
      <div class="p-form">
        <div class="p-item">
          <div class="p-title">
            学段科目：
          </div>
          <div>
            <el-cascader
              ref="subjects"
              placeholder="请选择学段科目"
              v-model="subject"
              :options="subjectList"
              :props="{ label: 'subjectValue', value: 'id' }"
              @change="searchChange"
            />
          </div>
        </div>
        <div class="p-item">
          <div class="p-title">
            年级：
          </div>
          <div>
            <el-select v-model="grade" placeholder="请选择年级" @change="searchChange">
              <el-option
                v-for="item in gradeList"
                :key="item.id"
                :label="item.value"
                :value="item.id"
              />
            </el-select>
          </div>
        </div>
        <div class="p-item">
          <div class="p-title">
            是否已打标签：
          </div>
          <div>
            <el-radio-group v-model="isLabel" @change="searchChange" class="labelGroup">
              <el-radio :label="true">已打标签({{ summary.labeled }})</el-radio>
              <el-radio :label="false">未打标签({{ summary.unlabeled }})</el-radio>
            </el-radio-group>
          </div>
        </div>
      </div>
    </div>
    <div class="p-main">
      <div class="p-content" v-if="questionList && questionList.length > 0">
        <question-item
          v-for="item in questionList"
          :key="item.questionId"
          :value="item"
          @playLabel="playLabel(item)"
        />
      </div>
      <div class="p-empty" v-else>
        <el-empty description="暂无数据"></el-empty>
      </div>
      <div class="p-page">
        <el-pagination
          layout="total, prev, pager, next, jumper"
          :current-page="currentPage"
          :page-size="size"
          :total="total"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>

    <play-label ref="playLabelComponent" @change="loadData" />
  </div>
</template>

<script>

import { commonSubsectionList } from '@/api/question'
import playLabel from './modules/playLabel'
import questionItem from './modules/questionItem'
import { Grade } from '@/config/enums/Grade.js'
import { getQueryQYQuesByGradeList } from '@/api/platformQuestion'

export default {
  components: {
    playLabel,
    questionItem,
  },
  data () {
    return {
      fullscreenLoading: false,
      subjectList: [],
      subject: [],
      isLabel: false,
      gradeList: Grade.filter(item => item.level === 1),
      grade: null,

      summary: {
        labeled: 0,
        unlabeled: 0,
      },
      isLabelMap: [],
      questionList: [],
      total: 0,
      currentPage: 1,
      size: 10,
    }
  },
  mounted () {
    const query = this.$route.query
    this.grade = query.grade || this.gradeList[0].id
    this.isLabel = query.isLabel === 'true'

    this.getSubject().then(() => {
      if (this.subjectList?.length > 0) {
        const subsectionId = query.subsectionId || this.subjectList[0]?.children[0]?.id
        if (subsectionId) {
          this.subject = [this.subjectList[0].id, Number(subsectionId)]
          this.loadData()
        }
      }
    })
  },
  methods: {
    searchChange () {
      this.$router.replace({
        name: 'PlatformQuestion',
        query: {
          grade: this.grade,
          isLabel: this.isLabel,
          subsectionId: this.subject.length === 2 ? this.subject[1] : undefined
        }
      })
      this.loadData()
    },
    handleCurrentChange (e) {
      this.currentPage = e
      this.questionList = []
      this.loadData()
    },
    getSubject () {
      return commonSubsectionList().then(res => {
        if (res.code === 0) {
          const list = res.data.filter(item => item.sort === 1)
          this.subjectList = list.map(m => ({
            id: 's' + m.sort,
            subjectValue: m.subsection,
            children: m.subjects
          }))
        } else {
          // this.$message.error(res.msg)
          throw new Error(res.msg)
        }
      })
    },
    loadData () {
      this.getData(this.subject[1], this.grade, this.isLabel, this.currentPage, this.size)
    },
    getData (subsectionId, grade, isLabel, currentPage, size) {
      this.fullscreenLoading = true
      getQueryQYQuesByGradeList(subsectionId, grade, isLabel, currentPage, size).then(res => {
        if (res.code === 0) {
          if (res.data) {
            const { isLabelMap, pageResult } = res.data
            if (isLabelMap) {
              isLabelMap.forEach(m => {
                this.summary[m.isLabel ? 'labeled' : 'unlabeled'] = m.count
              })
            } else {
              this.summary = {
                labeled: 0,
                unlabeled: 0,
              }
            }
            if (pageResult) {
              this.questionList = pageResult.list || []
              this.total = pageResult.total || 0
            } else {
              this.questionList = []
              this.total = 0
            }
          }
        } else {
          this.$message.error(res.msg)
        }
      }).finally(() => {
        this.fullscreenLoading = false
      })
    },

    // 打标签
    playLabel (question) {
      this.$refs.playLabelComponent.show(question)
    },
  }
}
</script>

<style lang="scss" scoped>
  .plat{
    padding: 10px 10px 0;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
  .p-header,.p-main{
    padding: 10px;
    background-color: #fff;
    border-radius: 4px;
    height: calc(100vh - 100px);
  }
  .p-header{
    width: 280px;
  }
  .p-main{
    width: calc(100% - 330px);
    .p-content,.p-empty{
      padding: 10px;
      height: calc(100vh - 160px);
      overflow: auto;
    }
    .p-empty{
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .p-page{
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 0 10px;
    }
  }
  .p-item{
    margin-bottom: 20px;
  }
  .p-title{
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 10px;
  }
  //.labelGroup {
  //  display: block;
  //
  //  label {
  //    display: block;
  //    margin-bottom: 8px;
  //  }
  //}
</style>
