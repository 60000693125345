import request from '@/utils/request'

/**
 * 菁优题库列表
 * @returns {AxiosPromise}
 */
export function getQueryQYQuesByGradeList (subsectionId, grade, isLabel, currentPage, size) {
  return request({
    url: '/recordQuestion/queryQYQuesByGrade',
    method: 'GET',
    params: {
      subsectionId,
      grade,
      isLabel,
      currentPage,
      size,
    }
  })
}

/**
 * 根据学段科目ID获取题型列表
 * @return {AxiosPromise}
 */
export function getJYQuestionType (subsectionId) {
  return request({
    url: '/common/getSubsectionQuestionType',
    method: 'GET',
    params: {
      subsectionId,
    }
  })
}

/**
 * 菁优题目打标签
 * @return {AxiosPromise}
 */
export function JYMakeLabel (data) {
  return request({
    url: '/recordQuestion/jy/bank/make/label',
    method: 'POST',
    data,
  })
}
