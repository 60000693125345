<template>
  <div style="margin-bottom: 10px">
    <div class="main">
      <div class="mainInfo">
        <div class="tagsWrapper">
          <span class="tag" v-if="value.isLabel">已打标签</span>
          <span class="tag highlight" v-else>未打标签</span>
        </div>
        <div class="stem-item">
          <div class="titleNumber" v-if="value.titleNumber">{{ value.titleNumber }}</div>
          <mathpad v-if="value.stem" :value="value.stem" />
          <template v-if="value.isCombination">
            <div class="subtopic" v-for="(item, index) in value.subtopicQuestion" :key="index">
              <div class="titleNumber">{{ index + 1 }})</div>
              <mathpad v-if="item.stem" :value="item.stem" />
            </div>
          </template>
        </div>
      </div>
      <div class="diffQuestionType">
        <div class="top">
          <div class="items">
            <div v-if="value.degreeOfDifficulty">难度：{{ value.degreeOfDifficulty | getDiffName }}</div>
            <div v-if="value.questionType">题型：{{ value.questionType | getQuestionType }}</div>
            <template v-if="value.auditInfo">
              <div v-if="value.auditInfo.recorderName">录题：{{ value.auditInfo.recorderName }}</div>
              <div v-if="value.auditInfo.auditName">审核：{{ value.auditInfo.auditName }}</div>
            </template>
          </div>
          <div class="items menus">
            <div @click="showAnalysis">{{ isShowAnalysis ? '收起解析' : '查看解析' }}</div>
            <div @click="playLabel">打标签</div>
          </div>
        </div>
        <!-- 默认题目解析 -->
        <default-analysis v-if="isShowAnalysis && !value.isCombination" :value="value" />
        <!-- 大小题解析 -->
        <combination-analysis v-if="isShowAnalysis && value.isCombination" :value="value" />
      </div>
    </div>
  </div>
</template>

<script>
import mathpad from '../../basicoperation/modules/Mathpad.vue'
import { degree, questionType } from '@/utils/enums.js'
import defaultAnalysis from './defaultAnalysis.vue'
import combinationAnalysis from './combinationAnalysis.vue'
export default {
  props: {
    value: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    mathpad,
    defaultAnalysis,
    combinationAnalysis
  },
  data () {
    return {
      isShowAnalysis: false
    }
  },
  methods: {
    // 查看解析
    showAnalysis () {
      this.isShowAnalysis = !this.isShowAnalysis
    },
    // 打标签
    playLabel () {
      this.$emit('playLabel')
    }
  },
  filters: {
    getDiffName (val) {
      let name = ''
      degree.forEach(item => {
        if (item.id === val) {
          name = item.value
        }
      })
      return name
    },
    getQuestionType (val) {
      let name = ''
      questionType.forEach(item => {
        if (item.id === val) {
          name = item.value
        }
      })
      return name
    }
  }
}
</script>

<style lang="scss" scoped>
.draggable {
  cursor: grab;

  .mainInfo .stem-item {
    margin-bottom: 0;
    max-height: 4em;
    overflow: hidden;
  }

  .tagsWrapper,
  .diffQuestionType {
    display: none;
  }
}

.main{
  border: 1px solid rgba(153,153,153,0.2);
  border-radius: 4px;
}
.main:hover{
  border: 1px solid #18C2C2;
}
.mainInfo{
  padding: 15px;

  .tag {
    display: inline-block;
    font-size: 12px;
    padding: 1px 10px;
    margin: 0 10px 5px 0;
    background-color: #18C2C2;
    border-radius: 2px;
    color: #fff;

    &.highlight {
      background-color: #FF5500;
    }
  }
  .off{
    background-color: #ffa600;
  }
  .af{
    background-color: #f91b0a;
  }
  .tbp{
    background-color: #2DB7F5;
  }
  .stem-item{
    margin-bottom: 15px;
    line-height: 20px;
  }
}
.diffQuestionType{
  font-size: 14px;
  font-weight: 600;
  color: #000;
  background-color: #E7F9F9;
  padding: 15px 20px;
  .top{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
.items{
  display: flex;
  align-items: center;
  div {
    margin-left: 20px;

    &:first-child {
      margin-left: 0;
    }
  }
}
.menus{
  div{
    color: #18C2C2;
    cursor: pointer;
  }
}
/deep/ .el-button--primary{
  background-color: #18C2C2;
  border: 1px solid #18C2C2;
  letter-spacing: 1px;
  width: 150px;
}

// 题号
.titleNumber {
  float: left;
  vertical-align: bottom;
  color: #999;
}

// 小题
.subtopic {
  margin-top: 5px;

  .titleNumber {
    margin: 0 5px 0 30px;
    min-width: 16px;
  }
}

.ui-mathpad {
  margin-left: 30px;
}
</style>
