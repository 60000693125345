<template>
  <el-dialog
    title="打标签"
    width="560px"
    :center="false"
    :visible.sync="playVisible"
  >
    <el-form size="small" class="setting-form" label-width="6em" label-position="top">
      <el-form-item label="选择题型:" class="form-item" required>
        <el-select v-model="formData.questionType" placeholder="请选择题型">
          <el-option
            v-for="item in questionTypeList"
            :key="item.questionType"
            :label="item.questionTypeName"
            :value="item.questionType">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="难易程度:" class="form-item" required>
        <el-radio-group v-model="formData.degreeOfDifficulty">
          <el-radio :label="item.id" v-for="item in degreeList" :key="item.id">{{ item.value }}</el-radio>
        </el-radio-group>
      </el-form-item>

      <template v-if="!isCombination">
        <el-form-item label="选择知识点:" class="form-item" required>
          <SelectKnowledge
            v-model="formData.knowledge"
            :subsectionSubjectId="subsectionSubjectId"
            :options="knowledgeOptions"
          />
        </el-form-item>

        <el-form-item label="选择核心素养（非必需）:" class="form-item">
          <SelectKeyAccomplishment
            v-model="formData.keyAccomplishment"
            :options="keyAccomplishmentOptions"
          />
        </el-form-item>
      </template>

      <div class="subtopic" v-for="(item, index) in subtopicData" :key="item.questionId" v-show="!item.isDelete">
        <div class="subtopic-hd">第{{ index + 1 }}小题</div>
        <el-form-item label="选择知识点:" class="form-item">
          <SelectKnowledge
            v-model="item.knowledge"
            :subsectionSubjectId="subsectionSubjectId"
            :options="knowledgeOptions"
          />
        </el-form-item>

        <el-form-item label="选择核心素养（非必需）:" class="form-item">
          <SelectKeyAccomplishment
            v-model="item.keyAccomplishment"
            :options="keyAccomplishmentOptions"
          />
        </el-form-item>
      </div>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button style="width: 80px" @click="playCancel">取 消</el-button>
      <el-button style="width: 80px" type="primary" @click="playOk">保 存</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { degree } from '@/utils/enums.js'
import { getKeyAccomplishmentList } from '@/api/keyAccomplishment'
import { getKnowledgePointList } from '@/api/knowledge'
import SelectKeyAccomplishment from './SelectKeyAccomplishment'
import SelectKnowledge from './SelectKnowledge'
import { getJYQuestionType, JYMakeLabel } from '@/api/platformQuestion'

export default {
  name: 'playLabel',
  components: {
    SelectKeyAccomplishment,
    SelectKnowledge,
  },
  props: {
    subsection: {
      type: Array,
      default: () => ([])
    }
  },
  data () {
    return {
      playVisible: false,
      questionTypeList: [], // 题型选项
      degreeList: degree, // 难易程度选项
      knowledgeOptions: null, // 知识点选项
      keyAccomplishmentOptions: null, // 核心素养选项

      isCombination: false, // 大小题标记
      subsectionSubjectId: null, // 学段学科ID
      formData: {
        questionType: null, // 题型
        degreeOfDifficulty: null, // 难易程度
        knowledge: [],
        keyAccomplishment: [],
      },
      subtopicData: [], // 小题属性

      info: null
    }
  },
  methods: {
    show (item) {
      this.info = item
      this.isCombination = item.isCombination
      this.formData.questionType = item.questionType
      this.formData.degreeOfDifficulty = item.degreeOfDifficulty
      this.formData.knowledge = item.knowledge || []
      this.formData.keyAccomplishment = item.keyAccomplishment || []
      // this.subtopicData = item.subtopicQuestion ? item.subtopicQuestion.map(sub => {
      //   return {
      //     chapter: sub.chapter,
      //     questionId: sub.questionId,
      //     knowledge: sub.knowledge || [],
      //     keyAccomplishment: sub.keyAccomplishment || [],
      //   }
      // }) : []

      if (item.subsectionId) {
        this.subsectionSubjectId = item.subsectionId
        this.getJYQuestionType(item.subsectionId)
        this.loadKnowledgePointList(item.subsectionId)
        this.loadKeyAccomplishmentList(item.subsectionId)
      }

      this.playVisible = true
    },
    playOk () {
      const params = Object.assign({
        questionId: this.info.questionId,
      }, this.formData)

      JYMakeLabel(params).then(res => {
        if (res.code === 0) {
          this.playVisible = false
          this.$message.success('操作成功')
          this.$emit('change')
        } else {
          this.$message.error(res.msg)
        }
      }, err => {
        console.log(err)
        this.$message.error('接口异常，打标签失败')
      })
    },
    playCancel () {
      this.playVisible = false
    },
    // 获取题型列表
    getJYQuestionType (subsectionId) {
      getJYQuestionType(subsectionId).then(res => {
        if (res.code === 0) {
          this.questionTypeList = res.data
        } else {
          this.$message({ type: 'error', message: res.msg })
        }
      }).catch(err => {
        this.$message({ type: 'error', message: err })
      })
    },
    // 知识点列表
    loadKnowledgePointList (subsectionSubjectId) {
      return getKnowledgePointList(subsectionSubjectId).then(res => {
        if (res.code === 0) {
          const list = this.disabledKnowData(res.data)
          this.knowledgeOptions = list
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    // 处理知识点数据,非最后一层级都不可选中
    disabledKnowData (list) {
      list.forEach(item => {
        if (item.children && item.children.length > 0) {
          item.disabled = true
          this.disabledKnowData(item.children)
        }
      })
      return list
    },
    // 加载核心素养列表
    loadKeyAccomplishmentList (subsectionId) {
      return getKeyAccomplishmentList(subsectionId).then(res => {
        if (res.code === 0) {
          this.keyAccomplishmentOptions = res.data
        } else {
          this.$message.error(res.msg)
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.item-box{
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  .item-title{
    font-size: 14px;
    font-weight: 600;
    color: #000;
  }
}
.item{
  align-items: flex-start;
}

.form-item{
  font-weight: 600;
}

.setting-form /deep/ {
  position: relative;
  padding: 0px 30px;
  max-height: calc(80vh - 240px);
  overflow: auto;

  .el-form-item__label {
    color: #000;
  }
}

.dlg-main {
  max-height: calc(60vh);
  overflow: auto;

  /deep/ .el-tree-node__children {
    overflow: visible;
  }
}
.dlg-footer {
  text-align: right;
  margin: 10px 0 -10px;
}

.subtopic {
  position: relative;
  padding: 5px;
  margin-bottom: 10px;
  border: 1px solid #eee;
  border-radius: 4px;

  &-hd {
    margin-top: 5px;
    color: #000;
  }

  .el-form-item {
    margin-left: 2em;
  }
}

.result {
  &-label {
    float: left;
  }

  &-list {
    margin-left: 50px;
    // line height: 32px;
    padding: 4px 0;
    line-height: 24px;
    color: #999;
  }
}
.result-list{
  font-weight: 400;
  color: #666666;
}
</style>
